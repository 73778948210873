import {createLogger, createStore} from 'vuex'
import NewDevicesModule from './modules/newDevices';
import UserModule from './modules/user';
import DeviceModule from './modules/device';
import HydraloopModule from './modules/hydraloop';
import ReportModule from './modules/reports';
import DashboardModule from './modules/dashboard';
import StandardView from '@/store/modules/standardView';

const debug = process.env.NODE_ENV !== "production";

// Create a new store instance.
const store = createStore({
  modules: {
    newDevices: NewDevicesModule,
    users: UserModule,
    device: DeviceModule,
    hydraloop: HydraloopModule,
    report: ReportModule,
    dashboard: DashboardModule,
    standardView: StandardView,
  },
  plugins: debug ? [createLogger()] : []
})

export default store;
