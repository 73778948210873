import httpCommon from "@/helpers/http-common";
import TagsHelper from "@/helpers/TagsHelper";

const DeviceModule = {
    namespaced: true,

    state: () => ({
        active: [],
        activeAdmin: [],
        inActive: [],
        newDevices: [],
        loading: false,
        loadingActive: false,
        listTmv: [],
        totalDeviceTmv: 0,
        totalDeviceMetering: 0,
        allTags: [],
        pureResponse: [],
        pureResponseInActive: []
    }),

    mutations: {
        setPureResponse(state, data) {
            state.pureResponse = data;
        },
        setPureResponseInActive(state, data) {
            state.pureResponseInActive = data;
        },
        setActive(state, data) {
            state.active = data;
        },
        setTags(state, data) {
            state.allTags = data;
        },
        setListTmv(state, data) {
            state.listTmv = data;
        },
        setInActive(state, data) {
            state.inActive = data;
        },
        setNewDevices(state, data) {
            state.newDevices = data
        },
        setLoading(state, data) {
            state.loading = data;
        },
        setLoadingActive(state, data) {
            state.loadingActive = data;
        },
        setTotalDeviceTmv(state, data) {
            state.totalDeviceTmv = data
        },
        setTotalDeviceMetering(state, data) {
            state.totalDeviceMetering = data
        },
        updateTags(state, {title, appId, devId, tags}) {
            state.active[title][appId][devId][0].tags = tags;
        }
    },

    getters: {
        getTotalDeviceTmv(state) {
            return state.totalDeviceTmv
        },
        getPureResponse(state) {
            return state.pureResponse
        },
        getPureResponseInActive(state) {
            return state.pureResponseInActive
        },
        getAllTags(state) {
            return state.allTags
        },
        getTotalDeviceMetering(state) {
            return state.totalDeviceMetering
        },
        getActive(state) {
            return state.active;
        },
        getInActive(state) {
            return state.inActive;
        },
        getNewDevices(state) {
            return state.newDevices
        },
        getLoading(state) {
            return state.loading
        },
        getLoadingActive(state) {
            return state.loadingActive
        },
        getListDeviceTmv(state) {
            return state.listTmv
        }
    },

    actions: {
        setLoading(context, data) {
            context.commit('setLoading', data);
        },
        loadGetTotalTmv(context) {
            httpCommon.get("/devices/get/total", {
                params: {
                    tags: "label:TMV"
                }
            })
              .then((response) => {
                  context.commit("setTotalDeviceTmv", response.data[0].total)
              })
              .catch((error) => {
                  console.log(error)
                  //console.log(error);
              })
              .finally(()=>{
                  context.commit('setLoading', false);
              });
        },
        loadGetTotalMetering(context) {
            httpCommon.get("/devices/get/total", {
                params: {
                    tags: "label:Water Meter"
                }
            })
              .then((response) => {
                  context.commit("setTotalDeviceMetering", response.data[0].total)
              })
              .catch((error) => {
                  console.log(error)
                  //console.log(error);
              })
              .finally(()=>{
                  context.commit('setLoading', false);
              });
        },
        updateDataActive(context, data) {
            const { app_id, dev_id, title, app_id_display, dev_id_display } = data.item;
            const appId = app_id_display || app_id
            const devId = dev_id_display || dev_id;
            // context.state.active[title][appId][devId].tags = data.tags;
            context.commit('updateTags', {
                title, appId, devId, tags: data.tags
            })
        },
        loadActive(context, filter=null) {
            context.commit('setLoadingActive', true);
            context.commit('setActive', []);
            var data = {
                is_active: true
            }
            if (filter) {
                data = {
                    ...data,
                    ...filter
                }
            }

            httpCommon.get("/devices", {
                params: data
            })
                .then((response) => {
                    const groupedData = {};
                    const uniqueTags = new Set();

                    for (const item of response.data) {
                        const {app_id, dev_id, title, app_id_display, dev_id_display, tags} = item;
                        const appId = app_id_display || app_id
                        const devId = dev_id_display || dev_id;

                        const endpointName = TagsHelper.getTagsBykey(tags, 'endpoint_name')
                        //const endpointLocation = TagsHelper.getTagsBykey(tags, 'endpoint_location')
                        const realTitle = endpointName && endpointName !== '---' ? endpointName : title;
                        if (tags) {
                            tags.forEach(tag => uniqueTags.add(tag));
                        }
                        if (!groupedData[realTitle]) {
                            groupedData[realTitle] = {};
                        }

                        if (!groupedData[realTitle][appId]) {
                            groupedData[realTitle][appId] = {};
                        }

                        if (!groupedData[realTitle][appId][devId]) {
                            groupedData[realTitle][appId][devId] = [];
                        }

                        groupedData[realTitle][appId][devId].push(item);
                    }
                    const sortedGroupedData = Object.keys(groupedData)
                    .sort((a, b) => a.localeCompare(b)) // Sort by realTitle
                    .reduce((result, realTitle) => {
                        result[realTitle] = Object.keys(groupedData[realTitle])
                        .sort((a, b) => a.localeCompare(b)) // Sort by appId
                        .reduce((appResult, appId) => {
                            appResult[appId] = Object.keys(groupedData[realTitle][appId])
                            .sort((a, b) => a.localeCompare(b)) // Sort by devId
                            .reduce((devResult, devId) => {
                                devResult[devId] = groupedData[realTitle][appId][devId];
                                return devResult;
                            }, {});
                            return appResult;
                        }, {});
                        return result;
                    }, {});
                    context.commit('setTags', Array.from(uniqueTags))
                    context.commit('setActive', sortedGroupedData);
                    context.commit('setPureResponse', response.data)
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(()=>{
                    context.commit('setLoadingActive', false);
                });
        },
        loadActiveSuperAdmin(context, filter=null) {
            context.commit('setLoadingActive', true);
            context.commit('setActive', []);
            var data = {
                is_active: true
            }
            if (filter) {
                data = {
                    ...data,
                    ...filter
                }
            }

            httpCommon.get("/devices", {
                params: data
            })
              .then((response) => {
                  const groupedData = {};
                  const uniqueTags = new Set();

                  for (const item of response.data) {
                      const { app_id, dev_id, title, tags, app_id_display, dev_id_display } = item;
                      const appId = app_id_display || app_id
                      const devId = dev_id_display || dev_id;
                      const endpointName = TagsHelper.getTagsBykey(tags, 'endpoint_name')
                      //const endpointLocation = TagsHelper.getTagsBykey(tags, 'endpoint_location')
                      const realTitle = endpointName && endpointName!=='---' ? endpointName : title;
                      if (tags) {
                          tags.forEach(tag => uniqueTags.add(tag));
                      }

                      if (!groupedData[realTitle]) {
                          groupedData[realTitle] = {};
                      }

                      if (!groupedData[realTitle][appId]) {
                          groupedData[realTitle][appId] = {};
                      }

                      if (!groupedData[realTitle][appId][devId]) {
                          groupedData[realTitle][appId][devId] = [];
                      }

                      groupedData[realTitle][appId][devId].push(item);
                  }

                  // Convert groupedData to an array of entries and sort by realTitle, appId, and devId
                  // Sort groupedData by realTitle, appId, and devId in ascending order
                  const sortedGroupedData = Object.keys(groupedData)
                  .sort((a, b) => a.localeCompare(b)) // Sort by realTitle
                  .reduce((result, realTitle) => {
                      result[realTitle] = Object.keys(groupedData[realTitle])
                      .sort((a, b) => a.localeCompare(b)) // Sort by appId
                      .reduce((appResult, appId) => {
                          appResult[appId] = Object.keys(groupedData[realTitle][appId])
                          .sort((a, b) => a.localeCompare(b)) // Sort by devId
                          .reduce((devResult, devId) => {
                              devResult[devId] = groupedData[realTitle][appId][devId];
                              return devResult;
                          }, {});
                          return appResult;
                      }, {});
                      return result;
                  }, {});

                  context.commit('setTags', Array.from(uniqueTags))
                  context.commit('setActive', sortedGroupedData);
                  context.commit('setPureResponse', response.data)
              })
              .catch((error) => {
                  console.log(error)
                  //console.log(error);
              })
              .finally(()=>{
                  context.commit('setLoadingActive', false);
              });
        },
        loadInActiveSuperAdmin(context, filter=null) {
            context.commit('setLoading', true);
            context.commit('setInActive', []);
            var data = {
                is_active: false
            }
            if (filter) {
                data = {
                    ...data,
                    ...filter
                }
            }

            httpCommon.get("/devices", {
                params: data
            })
              .then((response) => {
                  const groupedData = {};
                  // const uniqueTags = new Set();

                  for (const item of response.data) {
                      const { app_id, dev_id, title, app_id_display, dev_id_display, tags } = item;
                      const appId = app_id_display || app_id
                      const devId = dev_id_display || dev_id;

                      const endpointName = TagsHelper.getTagsBykey(tags, 'endpoint_name')
                      //const endpointLocation = TagsHelper.getTagsBykey(tags, 'endpoint_location')
                      const realTitle = endpointName && endpointName!=='---' ? endpointName : title;

                      if (!groupedData[realTitle]) {
                          groupedData[realTitle] = {};
                      }

                      if (!groupedData[realTitle][appId]) {
                          groupedData[realTitle][appId] = {};
                      }

                      if (!groupedData[realTitle][appId][devId]) {
                          groupedData[realTitle][appId][devId] = [];
                      }

                      groupedData[realTitle][appId][devId].push(item);
                  }
                  const sortedGroupedData = Object.keys(groupedData)
                  .sort((a, b) => a.localeCompare(b)) // Sort by realTitle
                  .reduce((result, realTitle) => {
                      result[realTitle] = Object.keys(groupedData[realTitle])
                      .sort((a, b) => a.localeCompare(b)) // Sort by appId
                      .reduce((appResult, appId) => {
                          appResult[appId] = Object.keys(groupedData[realTitle][appId])
                          .sort((a, b) => a.localeCompare(b)) // Sort by devId
                          .reduce((devResult, devId) => {
                              devResult[devId] = groupedData[realTitle][appId][devId];
                              return devResult;
                          }, {});
                          return appResult;
                      }, {});
                      return result;
                  }, {});
                  // context.commit('setTags', Array.from(uniqueTags))
                  context.commit('setInActive', sortedGroupedData);
                  context.commit('setPureResponseInActive', response.data)
              })
              .catch((error) => {
                  console.log(error);
              })
              .finally(()=>{
                  context.commit('setLoading', false);
              });
        },
        loadDeviceTmv(context) {
            context.commit('setLoading', true);
            httpCommon.get("/devices/list/tmv")
              .then((response) => {
                  context.commit('setListTmv', response.data);
              })
              .catch((error) => {
                  console.log(error)
                  //console.log(error);
              })
              .finally(()=>{
                  context.commit('setLoading', false);
              });
        },
        loadInActive(context, filter = null) {
            context.commit('setLoading', true);
            context.commit('setInActive', []);
            var data = {
                is_active: false
            }
            if (filter) {
                data = {
                    ...data,
                    ...filter
                }
            }
            httpCommon.get("/devices", {
                params: data
            })
              .then((response) => {
                  const groupedData = {};

                  for (const item of response.data) {
                      const {app_id, dev_id, title, app_id_display, dev_id_display, tags} = item;
                      const appId = app_id_display || app_id
                      const devId = dev_id_display || dev_id;

                      const endpointName = TagsHelper.getTagsBykey(tags, 'endpoint_name')
                      //const endpointLocation = TagsHelper.getTagsBykey(tags, 'endpoint_location')
                      const realTitle = endpointName && endpointName !== '---' ? endpointName : title;

                      if (!groupedData[realTitle]) {
                          groupedData[realTitle] = {};
                      }

                      if (!groupedData[realTitle][appId]) {
                          groupedData[realTitle][appId] = {};
                      }

                      if (!groupedData[realTitle][appId][devId]) {
                          groupedData[realTitle][appId][devId] = [];
                      }

                      groupedData[realTitle][appId][devId].push(item);
                  }
                  const sortedGroupedData = Object.keys(groupedData)
                  .sort((a, b) => a.localeCompare(b)) // Sort by realTitle
                  .reduce((result, realTitle) => {
                      result[realTitle] = Object.keys(groupedData[realTitle])
                      .sort((a, b) => a.localeCompare(b)) // Sort by appId
                      .reduce((appResult, appId) => {
                          appResult[appId] = Object.keys(groupedData[realTitle][appId])
                          .sort((a, b) => a.localeCompare(b)) // Sort by devId
                          .reduce((devResult, devId) => {
                              devResult[devId] = groupedData[realTitle][appId][devId];
                              return devResult;
                          }, {});
                          return appResult;
                      }, {});
                      return result;
                  }, {});
                  // context.commit('setTags', Array.from(uniqueTags))
                  context.commit('setInActive', sortedGroupedData);
                  context.commit('setPureResponseInActive', response.data)
              })
              .catch((error) => {
                  console.log(error);
              })
              .finally(()=>{
                context.commit('setLoading', false);
              });
        },
        loadNewDevice(context) {
            httpCommon.get("/devices", {
                params: {
                    is_active: null
                }
            })
                .then((response) => {
                    context.commit('setNewDevices', response.data);
                })
                .catch((error) => {
                    console.log(error)
                    //console.log(error);
                });
        }
    }
};

export default DeviceModule;
