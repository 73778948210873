class TagsHelper {
  // Response: true - validation is failed; false - everything is ok
  validateNewTag(tag, tags) {
    const result =
      this.validateNewTagDup("rate:", tag, tags);
    return result;
  }
  validateNewTagDup(tagName, tag, tags) {
    let result = true;
    if (tag.text && tag.text.length > 0 && tag.text.startsWith(tagName)) {
      result = tags.some((oldTag) => {
        if (oldTag && oldTag.text.startsWith(tagName) && oldTag.text !== tag.text) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      result = false;
    }
    return result;
  }
  normalizeTagName(tag) {
    const tagDelimeterIndex = tag.indexOf(":");
    if (tagDelimeterIndex > -1) {
      const tagName = tag.substring(0, tagDelimeterIndex);
      const tagNameLower = tagName.toLowerCase();
      return tag.replace(tagName, tagNameLower);
    } else {
      return tag.toLowerCase();
    }
  }

  getTagsBykey(tags ,key) {
    const t = tags.find((x) => {
      if (x && typeof x === 'object' && "text" in x) {
        return x.text.includes(key);
      } else if (typeof x === 'string') {
        return x.includes(key);
      }
      return false;
    });

    if (t) {
      if (typeof t === 'object' && t.text) {
        return t.text.split(":")[1] || "---";
      } else if (typeof t === 'string') {
        return t.split(":")[1] || "---";
      }
    }

    return "---";
  }
}

export default new TagsHelper()
